import { Button } from '@arckit/ui'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import React from 'react'

export default function NotFoundPage() {
	return (
		<Layout pageName="404">
			<div className="mt-10 px-4">
				<div className="mx-auto lg:w-2/3 2xl:w-1/3">
					<div className="relative mb-2 flex justify-center sm:mb-10">
						<h1 className="relative z-[2] max-w-max bg-white px-8 text-[10rem] !leading-tight tracking-wider sm:text-[12rem]">404</h1>
						<div className="absolute top-1/2 left-0 z-[1] h-2 w-full bg-ab-100"></div>
					</div>
					<div className="mb-6 flex justify-center">
						<span className="text-2xl font-medium">uh-oh! Page not found</span>
					</div>
					<div className="mb-8 flex flex-col items-center sm:mb-16">
						<span className="block text-xl font-normal text-gray-400">"Not all those who wander are lost."</span>
						<span className="block text-xl font-normal text-gray-400">– J.R.R. Tolkien, The Fellowship of the Ring</span>
					</div>
					<div className="mb-16 flex justify-center">
						<Link to="/">
							<Button size="2xl">Go back to the beginning</Button>
						</Link>
					</div>
					<div className="mb-10 flex justify-center">
						<img src="/images/404/404-book-2.png" alt="404 book" />
					</div>
				</div>
			</div>
		</Layout>
	)
}
